<template>
  <div id="app">
    <div id="fadein">
      <transition name="background-fade">
          <canvas id="canvas" ref="blurredImage" v-show="backgroundLoaded" ></canvas>
      </transition>
      <transition name="background-fade">
          <img id="background" alt="loading-image" :src="getCurrentBackground()" v-show="backgroundLoaded" :class="{backgroundAnimation : toggleBackgroundAnimation}">
      </transition>
    </div>
    <div id="container">
      <img id="d20" alt="d20 logo" src="./assets/d20-centered.svg">
      <TipText id="tip" :tip="currentTip"/> 
    </div>
    <div v-b-hover="hoverHandler">
      <b-icon-fullscreen style="position: fixed;right: 4%; bottom: 5%; width:auto; height:30px; color: #ffffff; cursor:pointer;" 
                          @click="toggleFullscreen"></b-icon-fullscreen>
    </div>

    <img id="banner" alt="dndbanner" src="@/assets/dnd-banner.png">
    

  </div>
</template>

<script>
// import StackBlur from 'stackblur-canvas';
const StackBlur = require('stackblur-canvas');

// Object.definePrototype(Vue.prototype, '$StackBlur', { value: StackBlur });

import TipText from './components/TipText.vue'
import tipsJSON from './assets/tips-db.csv';
import genericList from './assets/generic-img-list.json';

export default {
  name: 'App',
  components: {
    TipText,
  },
  data(){
    return{
      fullscreenIconIsHovered :  false,
      toggleBackgroundAnimation :  false,
      tips : tipsJSON,
      generics : genericList,
      currentIndex : 0,
      currentFilename : "ck3-castle-hill.jpg",
      currentTip : "",
      backgroundHeight : 0,
      isGeneric : false,
      backgroundLoaded : true,

    }
  },
  created(){
    window.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'Enter':
          this.toggleFullscreen();
          break;
        case 'Left':
        case 'ArrowLeft':
          // this.getPreviousBackground(); TODO
          break;
        case 'Right':
        case 'ArrowRight':
          this.getNextBackground();
          break;
      
        default:
          break;
      }
    });

    this.currentTip = this.tips[this.currentIndex]['Tip'];
    this.currentFilename = this.tips[this.currentIndex]['filename'];

    //Start slideshow
    this.loopSlideshow();
  },  
  methods:{
    onLoaded(){
      this.backgroundLoaded = true;
    },
    toggleFullscreen(){
      if (document.fullscreenElement != null){
        document.exitFullscreen()
          .catch((err) => console.error(err))
      }else{
        this.$el.requestFullscreen()
          .catch((err) => console.error(err))
      }
    },
    hoverHandler(isHovered) {
        this.fullscreenIconIsHovered = isHovered
    },
    getCurrentBackground(){
      if(this.isGeneric){
        return require('./assets/generic/' + this.currentFilename )
      }else{
        return require('./assets/imgs/' + this.currentFilename )
      }
    },
    drawBlurredCanvas(img){
      var canvas = this.$refs.blurredImage;
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img,0,0);
      var blurRadius = 20;
      StackBlur.canvasRGB(canvas, 0, 0, canvas.width, canvas.height, blurRadius);
    },
    async loopSlideshow(){
      //fade out animation
      setTimeout(() => this.backgroundLoaded = false, 13.5*1000);

      // Set new image and tip logic
      setTimeout(() => {
        do{
          this.currentIndex++;
          if(this.currentIndex >= this.tips.length){
            this.currentIndex = 0;
          }
        }while(!this.tips[this.currentIndex]['i-like-bool'])

        if(!this.tips[this.currentIndex]['has-img']){
          // TODO: Change to not display it and retry until this.tips[this.currentIndex]['has-img'] is true
          // this.isGeneric = true;
          var randIndex = this.currentIndex;
          do{
            // var randIndex = Math.floor(Math.random() * this.tips.length);
            randIndex++;
          }while(randIndex == this.currentIndex || this.tips[randIndex]['has-img'] == false)
          this.currentIndex = randIndex;
          this.currentFilename = this.tips[randIndex]['filename'];
          this.currentTip = this.tips[randIndex]['Tip'];
        }else{
          this.isGeneric = false;
          this.currentFilename = this.tips[this.currentIndex]['filename'];
          this.currentTip = this.tips[this.currentIndex]['Tip'];
        }

        //logic for background animation (portrait images)
        var img = new Image();
        var that = this;
        img.onload = function(){
          that.backgroundLoaded = true;
          if(img.height > img.width){
            // start animation
            // var translation = img.height - 1080;
            console.log('height, ' + this.height);
            
            // that.toggleBackgroundAnimation = true;
          }else{
            that.toggleBackgroundAnimation = false;
          }
        }
        // img.src = StackBlur.imageDataRGB(this.getCurrentBackground(), ID, )
        img.src = this.getCurrentBackground();

        var blurredImg = new Image();
        blurredImg.onload = function() {
            // self.drawBlurredCanvas(blurredImg)
            that.drawBlurredCanvas(blurredImg)
        }
        blurredImg.src = this.getCurrentBackground();

        this.loopSlideshow();
      }, 14*1000); //3.5*4 = 14 seconds
    },
    getNextBackground(){
      // update timer to false, call clearTimeout()
      console.log("Implement me")
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  height:100%;
  width:100%;
  overflow-y:hidden !important;
}

#background{
  position:fixed;

  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
}


.background-fade-enter-active, .background-fade-leave-active {
  transition: all 1.2s;
}

.background-fade-enter, .background-fade-leave-to{
  opacity: 0;
}

.background-blur-fade-enter-active, .background-blur-fade-leave-active {
  transition: all 3;
}

.background-blur-fade-enter, .background-blur-fade-leave-to{
  opacity: 0;
}


.backgroundAnimation{
  animation-name: imageTranslation;
  animation-duration: 14s;
  animation-iteration-count:infinite;
  animation-timing-function: ease-in-out;
}

@keyframes imageTranslation {
  0%{  transform: translate(-50%, -50%);}
  100%{  transform: translate(-50%, -75%);}
} 

#banner{
  position:fixed;

  top: 10%;
  left:-90px;
  height: 90px;
  width:auto;
}

#tip {
    margin-left: 50px;
    max-width: 900px;
}

#container{
  align-items: center;
  display:flex;
  position:fixed;
  margin:4%;
  bottom: 0px;
  background:rgba(0, 0, 0, 0.7);
}

#canvas{
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

#d20 {
  width: 10%;
  height: auto;

  animation-name: d20animation;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}

@keyframes d20animation {
  from{transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}
</style>