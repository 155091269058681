<template>
  <div class="tip">
      <!-- <div id="container"> -->
        <h2>{{ tip }}</h2>
      <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'TipText',
  props: {
    tip: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tip {
  text-align:left;
}

h2 {
    color: beige;
    margin-bottom: 0px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
